import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, ThemeProvider, createTheme } from '@mui/system';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { AccountData } from '@app/screens/incentive-configuration/types';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ExpandedCell from './ExpandedCell';
import If from '@app/components/If';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { changeTotalObjectToArr } from '@app/screens/incentive-configuration/helper';
import Dropdown from '@app/components/dropdown';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { incentiveSelector } from '@app/screens/incentive-configuration/redux/selectors';
import { NetworkService, ToasterService } from '@app/services';
import { API_PATH, TOAST_MESSAGES } from '@app/common/constants';
import { ToastType } from '@app/services/toaster';
import {
  fetchSalesTransferGoBtnCreator,
  fetchsalesDistributionListDataCreator,
  fetchsalesDistributionRemarksDataCreator,
  incentiveSaveCreator,
} from '@app/screens/incentive-configuration/redux/slice';
import ErrorPopup from '@app/screens/incentive-configuration/components/ErrorPopup';
import './style.css';


function SalesDistributionTable(props: any) {
  const dispatch = useDispatch();
  const [unmount, setUnmount] = useState(true);
  const [allData, setAllData] = useState<any>({});
  const [totalDataObj, setTotalDataObj] = useState<any>([]);
  const [companyTotalValueArr, setCompanyTotalValueArr] = useState<any>([]);
  const [avgTotalCompArr, setAvgTotalCompArr] = useState<any>([]);
  const [avgTotalNormalArr, setAvgTotalNormalArr] = useState<any>([]);
  const [warningPopup, setWarningPopup] = useState<boolean>(false);
  const [showWarningPopup, setShowWarningPopup] = useState<boolean>(false);
  const [isTableExpanded, setIsTableExpanded] = useState<boolean>(false)
  const [expendableId, setExpendableId] = useState<any>([]);

  const salesDistributionbackupDataList = useSelector(
    incentiveSelector.getSalesDistributionBackupList(),
    shallowEqual,
  );

  const salesDistributionDataList = useSelector(
    incentiveSelector.getSalesDistributionList(),
    shallowEqual,
  );
  const isFilterIconStatus = useSelector(
    incentiveSelector.getNavFilterIcon(),
    shallowEqual,
  );
  const save = useSelector(incentiveSelector.getSaveStatus);
  const remarksList = useSelector(
    incentiveSelector.getRemaksDataList(),
    shallowEqual,
  );
  const massage = useSelector(incentiveSelector.getSaleDistributionMssg);

  const saveSalesDistribution = async (from: string) => {
    try {
      let params = {};

      allData.mrDetails.forEach((ele: any) => {
        ele.accountData.forEach((_ele: any) => {
          _ele.monthData.forEach((__ele: any) => {
            __ele.segmentData.forEach((___ele: any) => {
              ___ele.incentiveAmount = parseFloat(___ele.incentiveAmount);
            });
          });
          _ele.averageData.forEach((__ele: any) => {
            __ele.incentiveAmount = parseFloat(__ele.incentiveAmount);
          });
        });
      });

      if (from === 'submit') {
        params = { ...allData, isSubmit: true };
      } else {
        params = { ...allData };
      }

      const response = await NetworkService.post(
        API_PATH.incentiveConfiguration.getSalesDistributionSave,
        params,
        {},
      );

      if (response.status !== 200) {
        ToasterService.showToaster(
          TOAST_MESSAGES.UNSUCCESSFULL_UPDATE,
          ToastType.ERROR,
        );
      } else {
        ToasterService.showToaster(
          TOAST_MESSAGES.SUCCESSFULLY_UPDATED,
          ToastType.SUCCESS,
        );
        dispatch(fetchsalesDistributionListDataCreator(props.filterDataReq));
      }
    } catch (error: any) {
      console.log('=== error ===', error);
    }
  };

  useEffect(() => {
    if (showWarningPopup) {
      const timerId = setTimeout(() => {
        setShowWarningPopup(false);
      }, 5000);
      return () => clearTimeout(timerId)
    }

  }, [showWarningPopup])

  useEffect(() => {
    if (save?.value === 'save') {
      if (warningPopup) {
        setShowWarningPopup(true)
      } else {
        saveSalesDistribution('save');
        dispatch(
          fetchSalesTransferGoBtnCreator({
            isEdited: false,
            isRefreshEditable: false,
          }),
        );
        dispatch(incentiveSaveCreator({ value: '' }));
      }
    }

    if (save?.value === 'refresh') {
      setAllData(salesDistributionbackupDataList)
      getTotalBarData(salesDistributionbackupDataList?.mrDetails);
      dispatch(
        fetchSalesTransferGoBtnCreator({
          isEdited: false,
          isRefreshEditable: false,
        }),
      );
      dispatch(incentiveSaveCreator({ value: '' }));
    }

    if (save?.value === 'submit') {
      saveSalesDistribution('submit');
      dispatch(
        fetchSalesTransferGoBtnCreator({
          isEdited: false,
          isRefreshEditable: false,
        }),
      );
      dispatch(incentiveSaveCreator({ value: '' }));
    }
  }, [save]);

  useEffect(() => {
    dispatch(fetchsalesDistributionRemarksDataCreator({}));
  }, []);

  function getMonthTotal(monthData: any, monthTotal: any) {
    const total = monthTotal || {};
    monthData?.length > 0 &&
      monthData?.map((month: any) => {
        month.segmentData.map((segment: any) => {
          const newTotal = total[month.monthId];
          if (newTotal) {
            const extSagment = newTotal[segment.segmentId];
            if (extSagment) {
              newTotal[segment.segmentId] += Number(segment.incentiveAmount);
            } else {
              newTotal[segment.segmentId] = Number(segment.incentiveAmount);
            }
          } else {
            total[month.monthId] = {
              [segment.segmentId]: Number(segment.incentiveAmount),
            };
          }
        });
      });
    return total;
  }

  const getAverage = (avgTotal: any, avg: any, accountType: any) => {
    return {
      ...avgTotal,
      ...getAvgTotal(avg, accountType, avgTotal),
    };
  };

  function getAvgTotal(averageData: any, accountType: any, avgTotal: any) {
    const avgTotalData = avgTotal || {};
    const avgNormal = avgTotalData[accountType];
    if (avgNormal) {
      const extAvg = avgNormal[averageData.segmentId];
      if (extAvg) {
        avgNormal[averageData.segmentId] += Number(averageData.incentiveAmount);
      } else {
        avgNormal[averageData.segmentId] = Number(averageData.incentiveAmount);
      }
    } else {
      avgTotalData[accountType] = {
        [averageData.segmentId]: Number(averageData.incentiveAmount),
      };
    }
    return avgTotalData;
  }

  function getTotalBarData(mrDetails: any) {
    let total = {};
    let companyTotal = {};
    let avgTotal = {};

    function updateTotal(account: any, type: string) {
      total = {
        ...total,
        ...getMonthTotal(account.monthData, total),
      };
    }

    function updateAverageData(account: any, type: string) {
      account?.averageData?.map((avg: any) => {
        avgTotal = getAverage(avgTotal, avg, type);
      });
    }

    function processNormalAccount(account: any) {
      updateTotal(account, 'Normal');
      updateAverageData(account, 'Normal');
    }

    function processCompanyAccount(account: any) {
      updateTotal(account, 'Company');
      updateAverageData(account, 'Company');
      companyTotal = {
        ...companyTotal,
        ...getMonthTotal(account.monthData, companyTotal),
      };
    }

    function processAccount(account: any) {
      switch (account.accountType) {
        case 'Normal':
          processNormalAccount(account);
          break;
        case 'Company':
          processCompanyAccount(account);
          break;
      }
    }

    function processMrDetailData(mrDetailData: any) {
      mrDetailData.accountData.map(processAccount);
    }

    mrDetails?.map(processMrDetailData);

    let normalArr = Object.values((avgTotal as any)?.Normal || {});
    let companyArr = Object.values((avgTotal as any)?.Company || {});

    const addTwoArrNumber = (arr1 : any, arr2: any) => {
      let newArr: any [] = [];
      for(let  i = 0; i < arr1.length; i++){
        let result = arr1[i] + arr2[i];
        newArr.push(result);
      }
       return newArr;
    }

    normalArr = addTwoArrNumber(normalArr, companyArr);

    setAvgTotalNormalArr(normalArr);
    setAvgTotalCompArr(companyArr);

    let totalArr = changeTotalObjectToArr(total);
    let companyTotalArr = changeTotalObjectToArr(companyTotal);

    let ans = arrChangeFunction(companyTotalArr);
    if (ans) {
      setCompanyTotalValueArr(ans);
    }

    let totalArrChange = arrChangeFunction(totalArr);
    if (totalArrChange) {
      setTotalDataObj(totalArrChange);
    }

    function arrChangeFunction(arr: any) {
      let count = salesDistributionDataList?.segmentHeader?.length;
      if (arr?.length > 0) {
        let segmentData = [];
        let result = [];
        for (const element of arr) {
          segmentData.push(element);
          if (segmentData.length == count) {
            let obj = { segmentData };
            result.push(obj);
            segmentData = [];
          }
        }
        return result;
      }
    }
  }

  const updateAccountData = (
    temp: any,
    ele: any,
    val: any,
    from: string,
    index: number,
    pIndex: number,
  ) => {
    ele.accountData.forEach((accd: any, idx: number) => {
      if (accd.accountType === 'Company') {
        if (from === 'normal') {
          temp.mrDetails[index].accountData[idx].monthData = val.monthData;

          let total = 0;
          temp.mrDetails[index].accountData[idx].monthData.forEach(
            (avg: any, avgIdx: number) => {
              total =
                total + Math.floor(avg.segmentData[pIndex].incentiveAmount);
            },
          );
          temp.mrDetails[index].accountData[idx].averageData[
            pIndex
          ].incentiveAmount = (total / 3).toFixed(2);
        } else if (from === 'avg') {
          console.log('----- val.averageData -----', val.averageData)
          temp.mrDetails[index].accountData[idx].averageData = val.averageData;
        } else if (from === 'remarks') {
          temp.mrDetails[index].accountData[idx].remarks = val.remarks;
        }
      }
    });
  };

  const expandedHandleOnChange = (val: any, from: string, index: number) => {
    let temp = JSON.parse(JSON.stringify({ ...allData }));
    dispatch(
      fetchSalesTransferGoBtnCreator({ isEdited: true, isRefreshEditable: true }),
    );
    setWarningPopup(false);

    temp?.mrDetails?.forEach((ele: any, idx: number) => {
      if (val?.monthData[0].segmentData[0].mrDetailId === ele.mrDetailId) {
        updateAccountData(temp, ele, val, from, idx, index);
      }
    });

    setAllData(temp);
    getTotalBarData(temp?.mrDetails);
  };

  useEffect(() => {
    setAllData(props?.salesDistributionDataList);
    const salesDistributionDataList = props?.salesDistributionDataList;
    getTotalBarData(salesDistributionDataList?.mrDetails);
  }, [props]);

  const handleOnChnage = useCallback(
    (ele: any, obj: any, parentData: any) => {
      dispatch(
        fetchSalesTransferGoBtnCreator({
          isEdited: true,
          isRefreshEditable: true,
        }),
      );
      let rejex = /^\d+(?:\.\d{0,2})?$/;
      let temp = JSON.parse(JSON.stringify({ ...allData }));

      const isNormalAccount = (accountType: string) => accountType === 'Normal';

      const updateIncentiveAmount = (val: any, index_key: number) => {
        val.segmentData.forEach((_val: any, _index: number) => {
          if (obj.segmentName === _val.segmentName) {
            let total = 0;
            temp.mrDetails[obj.mrDetailId - 1].accountData[0].monthData[
              index_key
            ].segmentData[_index].incentiveAmount = ele.target.value;
            temp.mrDetails[obj.mrDetailId - 1].accountData[0].monthData.forEach(
              (avg: any) => {
                total += Math.floor(avg.segmentData[_index].incentiveAmount);
              },
            );
            temp.mrDetails[obj.mrDetailId - 1].accountData[0].averageData[
              _index
            ].incentiveAmount = (total / 3).toFixed(2);
          }
        });
      };

      const processNormalAccount = (month: any) => {
        month.monthData.forEach((val: any, index_key: any) => {
          if (val.monthId === parentData.monthId) {
            updateIncentiveAmount(val, index_key);
          }
        });
      };

      setWarningPopup(false);
      if ((rejex.test(ele.target.value) || !ele.target.value) && ele.target.value <= 100) {
        try {
          temp.mrDetails[obj.mrDetailId - 1].accountData.forEach(
            (month: any) => {
              if (isNormalAccount(month.accountType)) {
                processNormalAccount(month);
              }
            },
          );
        } catch (error) {
          console.log('------ error ----', error);
        }
      }

      setAllData(temp);
      getTotalBarData(temp?.mrDetails);
    },
    [allData],
  );

  const handleBlurFunction = useCallback(() => {
    totalDataObj?.length &&
      totalDataObj?.forEach((val: any) => {
        val?.segmentData.forEach((ele: any) => {
          const roundedAmount = Math.floor(ele.amount);

          if (roundedAmount !== 100 && roundedAmount !== 0) {
            dispatch(
              fetchSalesTransferGoBtnCreator({
                isEdited: true,
                isRefreshEditable: true,
              }),
            );
            setWarningPopup(true);
          }
        });
      });
  }, [avgTotalCompArr, companyTotalValueArr, totalDataObj, avgTotalNormalArr]);


  const close = useCallback(() => {
    setShowWarningPopup(false);
  }, [showWarningPopup]);

  const renderSegmentData = (
    segmentData: any,
    parentData: any,
    from: string,
    pIndex: number,
  ) => {
    return segmentData?.map((_val: any, index: any) => (
      <>
        {allData.isSubmit || allData.isLocked || from === 'average' ? (
          <div
            key={_val?.incentiveAmount}
            style={{
              backgroundColor: '#cdcdcd',
              borderRadius: 5,
              height: 30,
              minWidth: 50,
              textAlign: 'right',
              padding: 5,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              fontWeight: 500,
            }}
          >
            {_val?.incentiveAmount}
          </div>
        ) : (
          <input
            style={{
              backgroundColor: pIndex === 1 ? '#ffdacc' : 'rgb(233, 233, 233)',
              borderWidth: 0,
              color: '#322b7c',
              borderRadius: 5,
              height: 30,
              minWidth: 50,
              maxWidth: 50,
              textAlign: 'right',
              padding: 5,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              fontWeight: 500,
            }}
            value={_val?.incentiveAmount}
            onChange={val =>
              from === 'normal' ? handleOnChnage(val, _val, parentData) : null
            }
            maxLength={6}
            onBlur={() => handleBlurFunction()}
          />
        )}
      </>
    ));
  };

  const handleRemarksChange = useCallback(
    (val: any, index: any) => {
      let temp = JSON.parse(JSON.stringify({ ...allData }));
        dispatch(
          fetchSalesTransferGoBtnCreator({
            isEdited: true,
            isRefreshEditable: true,
          }),
        );

      temp?.mrDetails[index]?.accountData?.forEach((ele: any, idx: number) => {
        if (ele.accountType === 'Normal') {
          temp.mrDetails[index].accountData[idx].remarks = val?.label;
        }
      });
      setAllData(temp);
    },
    [allData],
  );

  return (
    <>
      {(allData && allData?.mrDetails?.length === 0) || (allData?.error) || massage ? (
        <Box
          sx={{ color: '#322b7c', fontSize: 12, fontWeight: 500, marginLeft: 5 }}
        >
          {massage || allData?.error || 'No record Found'}
        </Box>
      ) : (
        <Box sx={{ marginTop: 0, marginLeft: 1 }}>
          {
            <>
              <ErrorPopup
                close={close}
                isVisible={showWarningPopup}
                massage={'Grand total cannot be less than 100%'}
              />

              <TableContainer
                sx={{ maxHeight: isFilterIconStatus.value ? '46vh' : '72vh', marginLeft: -5 }}
              >
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow style={{ background: '#fff' }}>
                      <TableCell
                        padding="checkbox"
                        style={{
                          background: 'white',
                          width: 150,
                          position: 'sticky',
                          left: 0,
                          top: 0,
                          zIndex: 999,
                        }}
                      />
                      <TableCell
                        style={{
                          width: 100,
                          background: 'white',
                          minWidth: 100,
                          paddingLeft: 8,
                          position: 'sticky',
                          left: 68.3,
                          paddingTop: '60px',
                          zIndex: 9,
                          paddingBottom: 10,
                          color: '#322B7C',
                          fontSize: 10.7
                        }}
                      >
                        SAP Code
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          width: 90,
                          position: 'sticky',
                          left: 168,
                          zIndex: 9,
                          paddingTop: '60px',
                          background: 'white',
                          color: '#322B7C',
                          fontSize: 10.7,
                          paddingLeft: 5
                        }}
                      >
                        Employee name
                      </TableCell>
                      {
                        !isTableExpanded && (
                          <React.Fragment>
                            <TableCell
                              align="left"
                              style={{
                                minWidth: 95,
                                zIndex: 'auto',
                                background: 'inherit',
                                paddingTop: '60px',
                                textAlign: 'left',
                                // paddingLeft: 45,
                                color: '#322B7C',
                                fontSize: 10.7
                              }}
                            >
                              doj
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                // width: 90,
                                zIndex: 'auto',
                                background: 'inherit',
                                paddingTop: '60px',
                                color: '#322B7C',
                                fontSize: 10.7
                              }}
                            >
                              station name
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                minWidth: 110,
                                zIndex: 'auto',
                                background: 'inherit',
                                paddingTop: '60px',
                                color: '#322B7C',
                                fontSize: 10.7
                              }}
                            >
                              FLM
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                minWidth: 110,
                                zIndex: 'auto',
                                background: 'inherit',
                                paddingTop: '60px',
                                color: '#322B7C',
                                fontSize: 10.7
                              }}
                            >
                              SLM
                            </TableCell>
                          </React.Fragment>
                        )
                      }
                      {allData &&
                        allData?.mrDetails?.length > 0 &&
                        allData?.mrDetails[0]?.accountData[0]?.monthData?.map(
                          (month: any, index: any) => {
                            const index_key = index.toString();
                            return (
                              <TableCell
                                align="left"
                                style={{
                                  position: 'sticky',
                                  zIndex: 'auto',
                                  // top: 5,
                                  paddingTop: 17,
                                  background: 'inherit',
                                }}
                                key={index_key}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                  }}
                                >
                                  <Box
                                    sx={{ padding: 0, margin: 0, zIndex: 'auto' }}
                                  >
                                    <Box
                                      sx={{
                                        zIndex: 'auto',
                                        background: 'inherit',
                                      }}
                                    >
                                      {month?.monthName}
                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      gap: 2.5,
                                      borderTop: '2px solid #000',
                                      borderColor: 'primary.main',
                                      zIndex: 'auto',
                                      background: 'inherit',
                                      paddingTop: 2,
                                    }}
                                  >
                                    {allData &&
                                      allData?.segmentHeader?.length > 0 &&
                                      allData?.segmentHeader.map(
                                        (_val: any, _index: any) => {
                                          return (
                                            <Box
                                              key={_val.name}
                                              sx={{ zIndex: 'auto', color: '#322B7C', fontSize: 10.7 }}
                                            >
                                              {_val.name}
                                            </Box>
                                          );
                                        },
                                      )}
                                  </Box>
                                </Box>
                              </TableCell>
                            );
                          },
                        )}

                      <TableCell
                        align="left"
                        style={{
                          position: 'sticky',
                          zIndex: 'auto',
                          // top: 5,
                          paddingTop: 17,
                          background: 'inherit',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            // paddingTop: 2
                          }}
                        >
                          <Box sx={{ padding: 0, margin: 0, zIndex: 'auto' }}>
                            <Box sx={{ zIndex: 'auto', background: 'inherit' }}>
                              {'AVERAGE '}
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: 2.5,
                              borderTop: '2px solid #000',
                              borderColor: 'primary.main',
                              zIndex: 'auto',
                              background: 'inherit',
                              paddingTop: 2,
                            }}
                          >
                            {allData &&
                              allData?.segmentHeader?.length > 0 &&
                              allData?.segmentHeader.map(
                                (_val: any, _index: any) => {
                                  return (
                                    <Box sx={{ zIndex: 'auto', color: '#322B7C', fontSize: 10.7 }} key={_val.name}>
                                      {_val.name}
                                    </Box>
                                  );
                                },
                              )}
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="left"
                        style={{
                          paddingTop: '55px',
                          color: '#322B7C',
                          fontSize: 10.7
                        }}>
                        Remark
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ zIndex: -1 }}>
                    {allData?.mrDetails?.map((row: any, row_index: number) => (
                      <ExpandableTableRow
                        key={row?.mrDetail?.userId}
                        val={row?.mrDetail?.userId}
                        expandComponent={row?.accountData}
                        unmount={unmount}
                        expandedHandleOnChange={expandedHandleOnChange}
                        handleBlurFunction={handleBlurFunction}
                        onUnMount={(val: boolean) => {
                          setUnmount(val);
                        }}
                        isTableExpanded={(val: boolean) => {
                          let expendableIdArr = [...expendableId];
                          if (expendableIdArr?.length > 0) {
                            let idx = expendableIdArr.findIndex(ele => ele === val);
                            if (idx !== -1) {
                              expendableIdArr = expendableIdArr.filter(((ele, index) => idx !== index));
                            } else {
                              expendableIdArr.push(val);
                            }
                          } else {
                            expendableIdArr.push(val);
                          }
                          setExpendableId(expendableIdArr);
                          if (expendableIdArr.length > 0) {
                            setIsTableExpanded(true);
                          } else {
                            setIsTableExpanded(false)
                          }
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            fontWeight: 500,
                            paddingLeft: 0,
                            // background: 'white',
                            paddingRight: 0,
                            position: 'sticky',
                            left: 70,
                            zIndex: 1,
                          }}
                        >
                          <Box sx={{ bgcolor: expendableId.includes(row?.mrDetail?.userId) ?  '#f2f9fc' : '#fff', p: 1 }}>
                            {row?.mrDetail?.sapCode}
                          </Box>
                        </TableCell>
                        <TableCell
                          style={{
                            height: 30,
                            padding: 5,
                            fontWeight: 500,
                            position: 'sticky',
                            left: 170,
                            zIndex: 1,
                            paddingLeft: 0,
                            width: 120,
                            whiteSpace: 'nowrap',
                            // fontSize: 10.7
                          }}
                        >
                          <Box sx={{ bgcolor:  expendableId.includes(row?.mrDetail?.userId) ? '#f2f9fc':'#fff', p: 1 }}>
                            {row?.mrDetail?.firstName + ' '}{row?.mrDetail?.middleName }
                          </Box>
                        </TableCell>
                        {!isTableExpanded && (
                          <>
                            <TableCell>
                              <div
                                style={{
                                  height: 30,
                                  textAlign: 'left',
                                  // padding: 5,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  fontWeight: 500,
                                  whiteSpace: 'nowrap'
                                }}
                              >
                                {row?.mrDetail?.doj}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  height: 30,
                                  minWidth: 120,
                                  width: '100%',
                                  textAlign: 'left',
                                  padding: 5,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  fontWeight: 500,
                                  whiteSpace: 'nowrap'
                                }}
                              >
                                {row?.mrDetail?.stationName}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  height: 30,
                                  whiteSpace: 'nowrap',
                                  textAlign: 'left',
                                  // padding: 5,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  fontWeight: 500,
                                }}
                              >
                                {row?.mrDetail?.flmName}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  height: 30,
                                  minWidth: 50,
                                  textAlign: 'left',
                                  // padding: 5,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  fontWeight: 500,
                                }}
                              >
                                {row?.mrDetail?.slmName}
                              </div>
                            </TableCell>
                          </>
                        )}

                        {row?.accountData[0]?.monthData?.map(
                          (month: any, index: any) => (
                            <TableCell key={month?.segmentData}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  flexDirection: 'row',
                                }}
                              >
                                {renderSegmentData(
                                  month?.segmentData,
                                  month,
                                  'normal',
                                  index,
                                )}
                              </Box>
                            </TableCell>
                          ),
                        )}
                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              flexDirection: 'row',
                            }}
                          >
                            {renderSegmentData(
                              row?.accountData[0]?.averageData,
                              {},
                              'average',
                              0,
                            )}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              margin: '0 auto',
                              width: '100%',
                              marginLeft: 10,
                              marginRight: 10,
                            }}
                          ></Box>
                          {row?.accountData?.map(
                            (_val: any, ___idx: number) => {
                              let idx = ___idx;
                              return (
                                <If
                                  condition={_val.accountType === 'Normal'}
                                  key={idx}
                                >
                                  <Dropdown
                                    size={'small'}
                                    onChange={(val: any) =>
                                      handleRemarksChange(val, row_index)
                                    }
                                    value={{ label: _val?.remarks }}
                                    dropdownList={remarksList || []}
                                    placeholder="Select Remarks"
                                  />
                                </If>
                              );
                            },
                          )}
                        </TableCell>
                      </ExpandableTableRow>
                    ))}
                  </TableBody>
                  <TableFooter
                    style={{
                      left: 0,
                      bottom: -2,
                      zIndex: 999,
                      position: 'sticky',
                    }}
                  >
                    <TableRow style={{ backgroundColor: '#2fb4ff'}}>
                      <TableCell
                        padding="checkbox"
                        style={{
                          position: 'sticky',
                          left: 0,
                          zIndex: 1,
                          backgroundColor: '#2fb4ff',
                        }}
                      />
                      <TableCell
                        colSpan={2}
                        style={{
                          color: '#ffffff',
                          fontSize: 11,
                          fontWeight: 700,
                          position: 'sticky',
                          left: 68,
                          zIndex: 1,
                          backgroundColor: '#2fb4ff',
                        }}
                      >
                        Grand Total
                      </TableCell>
                      {!isTableExpanded && (
                        <>
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                        </>
                      )}
                      {totalDataObj?.length > 0 &&
                        totalDataObj?.map((el: any, el_index: number) => {
                          let idx = el_index;
                          return (
                            <TableCell align="left" key={idx}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                }}
                              >
                                {el?.segmentData?.map(
                                  (_amn: any, amn_idx: number) => {
                                    let _idx = amn_idx;
                                    return (
                                      <div
                                        key={_idx}
                                        style={{
                                          border:
                                            _amn?.amount !== 100 &&
                                              _amn?.amount !== 0
                                              ? '1px solid red'
                                              : '',
                                          backgroundColor:
                                            el_index === 1
                                              ? '#ffdacc'
                                              : '#F4F4F4',
                                          borderRadius: 5,
                                          height: 30,
                                          minWidth: 50,
                                          textAlign: 'right',
                                          padding: 5,
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'center',
                                          fontWeight: 500,
                                          color: '#322b7c',
                                        }}
                                      >
                                        {_amn?.amount.toFixed(2)}
                                      </div>
                                    );
                                  },
                                )}
                              </Box>
                            </TableCell>
                          );
                        })}
                      <TableCell align="left">
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          {avgTotalNormalArr?.map((_el: any, index: number) => {
                            let idx = index;
                            return (
                              <div
                                key={idx}
                                style={{
                                  backgroundColor: '#F4F4F4',
                                  borderRadius: 5,
                                  height: 30,
                                  minWidth: 50,
                                  textAlign: 'right',
                                  padding: 5,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  fontWeight: 500,
                                  color: '#322b7c',
                                }}
                              >
                                {_el.toFixed(2)}
                              </div>
                            );
                          })}
                        </Box>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                    <TableRow  >
                       <TableCell colSpan={12}   className="total_tab_row" ></TableCell>
                    </TableRow>
                    <TableRow style={{ backgroundColor: '#322b7c'}}>
                      <TableCell
                        padding="checkbox"
                        style={{
                          position: 'sticky',
                          left: 0,
                          zIndex: 1,
                          backgroundColor: '#322b7c',
                        }}
                      />
                      <TableCell
                        colSpan={2}
                        style={{
                          color: '#ffffff',
                          fontSize: 11,
                          fontWeight: 700,
                          position: 'sticky',
                          left: 68,
                          zIndex: 1,
                          backgroundColor: '#322b7c',
                        }}
                      >
                        Company account bifurcation
                      </TableCell>
                      {!isTableExpanded && (
                        <>
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                        </>
                      )}

                      {companyTotalValueArr?.length > 0 &&
                        companyTotalValueArr?.map(
                          (el: any, el_index: number) => {
                            let index_key = el_index;
                            return (
                              <TableCell align="left" key={index_key}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  {el?.segmentData?.map(
                                    (_amn: any, amn_idx: number) => {
                                      let ky = amn_idx;
                                      return (
                                        <div
                                          key={ky}
                                          style={{
                                            backgroundColor:
                                              el_index === 1
                                                ? '#ffdacc'
                                                : '#F4F4F4',
                                            borderRadius: 5,
                                            height: 30,
                                            minWidth: 50,
                                            textAlign: 'right',
                                            padding: 5,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            fontWeight: 500,
                                            color: '#322b7c',
                                          }}
                                        >
                                          {_amn?.amount.toFixed(2)}
                                        </div>
                                      );
                                    },
                                  )}
                                </Box>
                              </TableCell>
                            );
                          },
                        )}

                      <TableCell align="left">
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          {avgTotalCompArr?.map((_el: any, index: number) => {
                            let index_key = index;
                            return (
                              <div
                                key={index_key}
                                style={{
                                  backgroundColor: '#F4F4F4',
                                  borderRadius: 5,
                                  height: 30,
                                  minWidth: 50,
                                  textAlign: 'right',
                                  padding: 5,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  fontWeight: 500,
                                  color: '#322b7c',
                                }}
                              >
                                {_el.toFixed(2)}
                              </div>
                            );
                          })}
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        <div
                          style={{
                            borderRadius: 5,
                            height: 30,
                            minWidth: 50,
                            width: 50,
                            textAlign: 'right',
                            padding: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            fontWeight: 500,
                            color: '#322b7c',
                          }}
                        ></div>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
              {allData.showDisclaimer ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: 10,
                  }}
                >
                  <div>
                    <span
                      style={{
                        fontFamily: 'Poppins',
                        fontWeight: 600,
                        fontSize: 12,
                        color: 'red',
                      }}
                    >
                      Disclaimer -
                    </span>
                    <span
                      style={{
                        fontFamily: 'Poppins',
                        fontWeight: 600,
                        fontSize: 12,
                        color: 'red',
                      }}
                    >
                      {' '}
                      You will be able to submit the details if the Total Sum is
                      100%
                    </span>
                  </div>
                </div>
              ) : null}
            </>
          }
        </Box>
      )}
    </>
  );
}

export default SalesDistributionTable;

const ExpandableTableRow = (props: any) => {
  const [isExpanded, setIsExpanded] = useState(false);


  const getChild = useMemo(() => {
    return (
      <React.Fragment>
        {props?.expandComponent?.map((row: AccountData, index: any) => {
          const index_key = index.toString();
          return (
            <If condition={row.accountType === 'Company'} key={index_key}>
              <ExpandedCell
                row={row}
                onUnMount={props.onUnMount}
                unmount={props.unmount}
                handleOnChange={props.expandedHandleOnChange}
                handleBlurFunction={props.handleBlurFunction}
              />
               <TableRow  >
                 <TableCell colSpan={12}   className="row_gap1"  sx={{backgroundColor: 'red'}}></TableCell>
             </TableRow>
            </If>
          );
        })}
      </React.Fragment>
    );
  }, [open, props]);
  return (
    <>
      <TableRow {...props.otherProps}
       className='main_table_row' 
      sx={{ backgroundColor: isExpanded ? '#f2f9fc' : '#fff'}}
      >
        <TableCell
          padding="checkbox"
          style={{
            position: 'sticky',
            left: 15,
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              bgcolor: isExpanded ? '#f2f9fc' : '#fff',
              // padding: 5
            }}
          >
            {props?.expandComponent?.length > 0 ? (
              <IconButton
                sx={{ height: 30 }}
                onClick={() => {
                  setIsExpanded(!isExpanded);
                  props.isTableExpanded(props.val)
                }}
              >
                <If condition={isExpanded}>
                  <RemoveCircleOutlineIcon style={{ color: '#322b7c' }} />
                </If>
                <If condition={!isExpanded}>
                  <AddCircleOutlineIcon style={{ color: '#322b7c' }} />
                </If>
              </IconButton>
            ) : null}
          </Box>
        </TableCell>
        {props.children}
      </TableRow>
      
      {isExpanded && getChild}
      <If condition={isExpanded}>
      <TableRow  >
          <TableCell colSpan={12}   className="row_gap" ></TableCell>
      </TableRow>
      </If>
    </>
  );
};
